import { translate } from 'vue-gettext';

const { gettext: $gettext } = translate;

const applicant = new Map([
  ['businessLoanStart', {
    dataLayerEvents: {
      add: {
        getters: {},
      },
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Start'),
  }],
  ['businessLoanCompanySelection', {
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Company Selection'),
  }],
  ['businessLoanCompanyInformation', {
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Corporate information'),
  }],
  ['businessLoanApplicant', {
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Guarantee'),
  }],
  ['businessLoanOffer', {
    dataLayerEvents: {
      checkout: {
        getters: {
          option: 'offerStatus',
        },
      },
      setApplicationStatus: {
        ignoreStepNumber: true,
        getters: {
          status: 'offerStatus',
        },
      },
    },
    title: $gettext('Loan Offer'),
  }],
  ['businessLoanSignees', {
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Signatories and beneficiaries'),
  }],
  ['businessLoanTerms', {
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Agree and Sign'),
  }],
]);

const signee = new Map([
  ['businessLoanSigneeStart', {
    dataLayerEvents: {
      add: {
        getters: {},
      },
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Signature'),
  }],
  ['businessLoanTerms', {
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Agree and Sign'),
  }],
]);

export default {
  applicant,
  additionalSteps: ['businessLoan'],
  initial: applicant,
  signee,
};
