const files = require.context('./data/', false, /\.js$/);

const items = files.keys().reduce((products, filePath) => {
  const product = files(filePath).default;
  const name = filePath.replace(/(\.\/|\.js)/g, '');

  products.set(name, product);

  return products;
}, new Map());

export default items;
