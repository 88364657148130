import { translate } from 'vue-gettext';

const { gettext: $gettext } = translate;

const applicant = new Map([
  ['carLoanStart', {
    dataLayerEvents: {
      add: {
        getters: {},
      },
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Basic information'),
  }],
  ['customerQuestions', {
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
      setApplicationStatus: {
        ignoreOnPageRefresh: true,
        ignoreStepNumber: true,
        parameters: {
          status: 'started',
        },
      },
    },
    title: $gettext('Customer information'),
  }],
  ['loanOffer', {
    dataLayerEvents: {
      checkout: {
        getters: {
          option: 'offerStatus',
        },
      },
      setApplicationStatus: {
        ignoreStepNumber: true,
        getters: {
          status: 'offerStatus',
        },
      },
    },
    title: $gettext('Loan offer'),
  }],
  ['carLoanTerms', {
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Agree and Sign'),
  }],
  ['customerReady', {
    dataLayerEvents: {
      purchase: {
        ignoreOnPageRefresh: true,
        ignoreStepNumber: true,
        getters: {
          id: 'applicationId',
          revenue: 'revenue',
        },
        parameters: {
          quantity: 1,
        },
      },
      setApplicationStatus: {
        ignoreStepNumber: true,
        parameters: {
          status: 'completed',
        },
      },
    },
    title: $gettext('Thank You'),
  }],
]);

export default {
  additionalSteps: ['carLoan'],
  applicant,
  initial: applicant,
};
