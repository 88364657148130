import { translate } from 'vue-gettext';

const { gettext: $gettext } = translate;

const applicant = new Map([
  ['start', {
    dataLayerEvents: {
      add: {
        getters: {},
      },
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Preliminary informations'),
  }],
  ['basicInformation', {
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Basic information'),
  }],
  ['customerQuestions', {
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
      setApplicationStatus: {
        ignoreOnPageRefresh: true,
        ignoreStepNumber: true,
        parameters: {
          status: 'started',
        },
      },
    },
    title: $gettext('Customer information'),
  }],
  ['kreditz', {
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
      solvencyCheckStarted: {
        ignoreStepNumber: true,
        getters: {},
      },
      setApplicationStatus: {
        ignoreOnPageRefresh: true,
        ignoreStepNumber: true,
        parameters: {
          status: 'solvencyCheck',
        },
      },
    },
    title: $gettext('Verification of solvency'),
  }],
  ['bankAccountSelection', {
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Bank account selection'),
  }],
  ['loanOffer', {
    dataLayerEvents: {
      checkout: {
        getters: {
          option: 'offerStatus',
        },
      },
      setApplicationStatus: {
        ignoreStepNumber: true,
        getters: {
          status: 'offerStatus',
        },
      },
    },
    title: $gettext('Loan offer'),
  }],
  ['continuousLoanTerms', {
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Confirm loan agreement'),
  }],
  ['complete', {
    dataLayerEvents: {
      purchase: {
        ignoreOnPageRefresh: true,
        ignoreStepNumber: true,
        getters: {
          id: 'applicationId',
          revenue: 'revenue',
        },
        parameters: {
          quantity: 1,
        },
      },
      setApplicationStatus: {
        ignoreStepNumber: true,
        parameters: {
          status: 'completed',
        },
      },
    },
    title: $gettext('Credit decision complete'),
  }],
]);

export default {
  initial: applicant,
  applicant,
};
