import { translate } from 'vue-gettext';

const { gettext: $gettext } = translate;

const applicant = new Map([
  ['smePosLoanApplicantStart', {
    dataLayerEvents: {
      add: {
        getters: {},
      },
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Start'),
  }],
  ['businessLoanOffer', {
    dataLayerEvents: {
      checkout: {
        getters: {
          option: 'offerStatus',
        },
      },
      setApplicationStatus: {
        ignoreStepNumber: true,
        getters: {
          status: 'offerStatus',
        },
      },
    },
    title: $gettext('Loan Offer'),
  }],
  ['smePosBusinessLoanTerms', {
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Agree and Sign'),
  }],
]);

const merchant = new Map([
  ['smePosMerchantStart', {
    dataLayerEvents: {
      add: {
        getters: {},
      },
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Start'),
  }],
  ['smePosCompanyInformation', {
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Corporate information'),
  }],
  ['smePosApplicant', {
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Guarantor'),
  }],
  ['smePosOwnersInformation', {
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
    },
    title: $gettext('The beneficial owners'),
  }],
  ['smePosMerchantComplete', {
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
    },
    title: $gettext('Application started'),
  }],
]);

export default {
  applicant,
  additionalSteps: ['businessLoan'],
  initial: merchant,
  merchant,
};
